
import Vue from "vue";

export default Vue.extend({
	name: "CampaignSummary",

	props: {
		items: {
			type: Array,
			default: function() {
				return [] as any[];
			}
		},
		open: {
			type: Boolean,
			default: false
		}
	},

	components: {},

	data: () => ({
		title: "CampaignSummary",
		campaign_summary: [
			{
				campaign_id: 1,
				advertiser_name: "Advertiser Naem",
				name: "Line Name",
				budget: "100 (imps)",
				spent: "0.0",
				start_date: "11 | 02 | 21",
				end_date: "11 | 02 | 21",
				associated_lines: 2,
				active: true,
				budget_remaining: 200
			}
		]
	}),

	created() {},

	mounted() {},

	computed: {},
	methods: {
		toggleOpen() {
			this.$emit("open", Boolean(!this.open));
		}
	},
	watch: {}
});
