
import Vue from "vue";
// @ts-ignore
import { isEmpty, isNull, isUndefined, last } from "lodash";
// @ts-ignore
import { SegmentTreeView } from "@/interfaces/segment";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
import {
	filterCurrentSelection,
	filterNoInSelection,
	filterByKey,
	// @ts-ignore
} from "@/utils/filter-global";
// @ts-ignore
import { processSegmentSelection } from "@/utils/resolveObjectArray";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

const YOUR_SEGMENT = "yourSegments";

export default Vue.extend({
	name: "termUser",
	props: {
		keySegment: {
			type: String,
			required: true,
		},
		line: {
			type: Object,
			default: function () {
				return {};
			},
		},
		user: {
			type: Object,
			default: function () {
				return {};
			},
		},
		predicates: {
			type: Object,
			default: function () {
				return {};
			},
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: {},
	data: () => ({
		tab: "user",
		items: [] as Array<any>,
		selection: [] as Array<string>,
		open: ["public"],
		selectable: false,
		progressBar: false,
	}),
	created() {},
	async mounted() {
		this.$nextTick(async () => {
			await this.load();
		});
	},
	computed: {
		...mapGetters("profile", ["isAccountRM"]),
		getKeySegments() {
			return this.keySegment;
		},
		isSegmentThird() {
			return this.getKeySegments != YOUR_SEGMENT;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("segment", [
			"getTreeViewSegments",
			"getTreeViewSegmentsThird",
		]),

		isNotMore(item: any){
			return item.key != "MORE";
		},

		isCategoryItem(value: any) {
			return value == "category";
		},

		async load(){
			await this.fetch();
			await this.updateDataCount();
			this.updateDataCountSelected();
		},

		change(event: any, item: any){
			if(event){
				let older = new Array();
				this.selection.forEach(element => {
					older.push(element);
				});
				this.selection.push(item.key);
				this.selectionM(this.selection, older);
			}else{
				let older = new Array();
				this.selection.forEach(element => {
					older.push(element);
				});
				let index = this.selection.findIndex(i => i == item.key);
				this.selection.splice(index, 1);
				this.selectionM(this.selection, older);
			}	
		},

		getFilter(dataFilter: any, isSegmentThird: boolean, pagination?: any): SegmentTreeView {
			const { limit, page } = pagination;
			
			return isSegmentThird 
				? this.createSegmentThirdFilter(dataFilter, limit, page)
				: this.createNonSegmentThirdFilter(dataFilter, limit, page);
		},

		createSegmentThirdFilter(dataFilter: any, limit: number, page: number): SegmentTreeView {
			const baseFilter = { limit, page };

			if (isNull(dataFilter)) {
				return { ...baseFilter, data_provider_key: this.getKeySegments };
			}

			const { provider_key, key } = dataFilter;
			return isNull(key) 
				? { ...baseFilter, data_provider_key: provider_key }
				: { ...baseFilter, data_provider_key: provider_key, segment_tree: key };
		},

		createNonSegmentThirdFilter(dataFilter: any, limit: number, page: number): SegmentTreeView {
			const baseFilter = { limit, page, advertiser_id: this.line.advertiser_id };

			if (isNull(dataFilter)) {
				
				if(!this.isAccountRM) return baseFilter;

				return {
					...baseFilter, 
					account_id: this.line.account_id, 
					line_item_id: this.line.id
				};
			}

			const { id } = dataFilter;
			return isNull(id) 
				? baseFilter
				: { ...baseFilter, parent_id: id };
		},


		async fetch() {
			this.progressBar = true;
			await this.setTreeViewSegments(null, null, this.isSegmentThird, {limit: 10, page: 1});
			this.progressBar = false;
		},

		async fetchChildren(item: any) {
			try {
				if (isEmpty(item)) return;
				if (isUndefined(item.sub_categories_count)) return;
				if (isUndefined(item.explandible)) return;
				await this.setLoadingData(TypeLoading.user_search);
				if (this.isSegmentThird) {
					let dataFilter = {
						provider_key: item.data_provider_key,
						key: item.key,
					};
					await this.setTreeViewSegments(dataFilter, item, true, {limit: 10, page: 1});
				} else {
					let dataFilter = {
						id: item.id,
					};
					await this.setTreeViewSegments(dataFilter, item, false, {limit: 10, page: 1});
				}
				item.explandible = true;
				await this.setLoadingData();
			} catch (error) {
				console.error("fetchChildren", error);
			} finally {
				await this.setLoadingData();
			}
		},

		async setTreeViewSegments(
			dataFilter: any,
			itemSelected: any,
			segmentThird: Boolean,
			paginado?: any
		) {

			let params = {
				filters: this.getFilter(dataFilter, segmentThird, paginado),
			};

			const treeview: Array<any> = await this.fetchData(params);
			if (isEmpty(treeview)) return;

			await this.buildItem(treeview, itemSelected);

			const itemButton = {
				name: "Load More",
				has_categories: false,
				key: "MORE",
				children: undefined,
				paginado: {
					limit: paginado.limit,
					page: paginado.page + 1
				},
				filter: dataFilter,
				parent: itemSelected,
				check: true
			}

			if (isNull(itemSelected)) {
				let itemMore = this.items.find(s => s.key == "MORE");
				if(isUndefined(itemMore)){
					this.items.push(itemButton);
				}else{
					let index = this.items.findIndex(s => s.key == "MORE");
					this.items.splice(index, 1);
					this.items.push(itemButton);
				}
			} else {
				let itemMore = itemSelected.children.find(s => s.key == "MORE");
				if(isUndefined(itemMore)){
					itemSelected.children.push(itemButton);
				}else{
					let index = itemSelected.children.findIndex(s => s.key == "MORE");
					itemSelected.children.splice(index, 1);
					itemSelected.children.push(itemButton);
				}
			}
		},

		async buildItem(treeview: Array<any>, itemSelected: any,){
			treeview.forEach(async (t: any) => {

				const item = {
					id: this.isSegmentThird ? t.key : t.id,
					name: t.name,
					key: t.key,
					external_id: t.external_id,
					segments_count: t.segments_count,
					verb_usage_id: isUndefined(t.verb_usage?.id)
						? undefined
						: t.verb_usage?.id,
					verb_usage_name: isUndefined(t.verb_usage) ? undefined : t.verb_usage,
					is_category: this.isCategoryItem(t.object_type),
					segment_nam: t.segment_nam,
					data_provider_key: this.isSegmentThird
						? t.data_provider_key
						: undefined,
					explandible:
						this.isSegmentThird && this.isCategoryItem(t.object_type)
							? false
							: isUndefined(t.sub_categories_count)
							? undefined
							: false,
					has_categories: this.isSegmentThird
						? this.isCategoryItem(t.object_type)
						: isUndefined(t.sub_categories_count)
						? false
						: true,
					children:
						this.isSegmentThird && this.isCategoryItem(t.object_type)
							? []
							: isUndefined(t.sub_categories_count)
							? undefined
							: [],

					segment_tree: t.hasOwnProperty("segment_tree")
						? isUndefined(t.segment_tree)
							? undefined
							: t.segment_tree
						: undefined,
					sub_categories_count:
						this.isSegmentThird && this.isCategoryItem(t.object_type)
							? 0
							: t.sub_categories_count,
					user_count_nam: this.isSegmentThird ? t.user_count_nam : 0,
					user_count_emea: this.isSegmentThird ? t.user_count_emea : 0,
					user_count_apac: this.isSegmentThird ? t.user_count_apac : 0,
					cpm_cost: this.isSegmentThird ? t.cpm_cost : 0,
					seg_third: this.isSegmentThird
				};

				const filteredTerms: Array<any> = await filterByKey(
					this.user.segment.targeting_terms,
					item.key,
					"value"
				);

				if (!isEmpty(filteredTerms)) {
					this.selection.push(item.key);
				}

				if (isNull(itemSelected)) {
					this.items.push(item);
				} else {
					itemSelected.children.push(item);
				}
			});
		},

		async fetchData(params: any) {
			if (this.getKeySegments === YOUR_SEGMENT) {
				return await this.getTreeViewSegments(params);
			} else {
				params.filters.data_provider_key = this.getKeySegments;
				return await this.getTreeViewSegmentsThird(params);
			}
		},

		async updateDataCount() {
			let params = {
				keySegment: this.getKeySegments,
				count: this.items.length - 1,
			};
			this.$emit("update-data-count", params);
		},

		updateDataCountSelected() {
			let params = {
				keySegment: this.getKeySegments,
				count: this.selection.length,
			};
			this.$emit("update-data-selected", params);
		},

		async removeHandler(key: any, value: any, is_unique: Boolean = false) {
			this.$emit("remove-item", {
				unique: is_unique,
				params: {
					tab: this.tab,
					key: "segment",
					value: key,
					is_unique: is_unique,
				},
			});
			await this.removeSelection(key);
		},

		async removeSelection(key: any) {
			this.selection.forEach((element, index) => {
				if (element === key) this.selection.splice(index, 1);
			});
		},

		async updateWatchByKey(
			key: String,
			val: Array<any>,
			old: Array<any>,
			is_unique: Boolean = false
		) {
			let item: Array<any> = [];
			const current: Array<any> = val || [];
			const older: Array<any> = old || [];

			if (current.length > older.length) {
				item = current.filter(function (o: any) {
					return !(older.filter((e) => e.key === o.key).length > 0);
				});
			}

			if (current.length <= older.length) {
				item = older.filter(function (o: any) {
					return !(current.filter((e) => e.key === o.key).length > 0);
				});
			}

			const element = !isEmpty(item) ? last(item) : null;

			if (!element || element?.has_categories || element?.is_category) return;

			const params = {
				tab: this.tab,
				key: key,
				value: element.key,
				verb_usage: element.verb,
				segment_name: element.segment_name,
				verb_usage_name: element.verb_usage_name,
				is_category: element.is_category,
			};

			this.user.segment.value = current;

			const fire = current.length > older.length ? "add-item" : "remove-item";

			this.$emit(fire, { unique: is_unique, params: params });

			this.updateDataCountSelected();
		},

		async updateWatchBySegment(val: Array<any>, old: Array<any>) {
			const noInSelection: Array<string> = await filterNoInSelection(
				this.selection,
				old
			);

			if (isEmpty(noInSelection)) return;

			const newSelection: Array<string> = await filterCurrentSelection(
				this.selection,
				noInSelection
			);

			this.selection = newSelection;

			this.updateDataCountSelected();
		},

		async updateAllSegments(){
			await this.updateTreeview();
			this.updateDataCountSelected();
		},

		async updateTreeview(){
			this.user.segment.targeting_terms.forEach((s) => {
				if(!this.selection.some(v => v == s.value)){
					this.selection.push(s.value);
				}				
			});
		},

		async loadMore(item: any){
			await this.setLoadingData(TypeLoading.loading);
			await this.setTreeViewSegments(item.filter, item.parent, this.isSegmentThird, {limit: 10, page: item.paginado.page});
			await this.updateDataCount();
			this.updateDataCountSelected();
			await this.setLoadingData();
		},

		async selectionM(val: Array<string>, old: Array<string>) {
			const current: Array<string> = val;
			const older: Array<string> = old;
			const treeview: Array<any> = this.items;

			if (isEmpty(treeview)) return;

			const { newValue, oldValue } = await processSegmentSelection(
				current,
				older,
				treeview
			);

			if (isEmpty(newValue) && isEmpty(oldValue)) return;

			await this.updateWatchByKey("segment", newValue, oldValue, false);
		},
	},
	watch: {
		async "user.segment.targeting_terms"(newValue, oldValue) {
			await this.updateWatchBySegment(newValue, oldValue);
		},
	},
});
