
import Vue from 'vue';
// @ts-ignore
import Buttons from "@/views/Admin/Commons/Buttons.vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import AssociationTable from "@/views/Admin/LineItem/Dependencies/Creatives/AssociationTable.vue";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import { LineItemAsso, ResultPaginateAsso } from "@/interfaces/line_item";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { mapGetters, mapActions } from "vuex";
// @ts-ignore
import { isUndefined, isNull } from "lodash";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import { getPermisionLineItem } from "@/utils/permissionResolve";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

const dictionary = {
	id: "id",
	creative_name: "creatives.name",
	creative_external_id: "creatives.external_id"
}

export default Vue.extend({
    name: "AssociationTableBase",
    props:{
		line_item: {
			type: Object,
			default: function () {
				return {};
			},
		},
        headers: {
			type: Array,
			default: function () {
				return [];
			},
		},
		readonly:{
			type:Boolean,
			default: false
		}
    },
    components:{
        Buttons,
        Alertize,
		AssociationTable,
		NotPermission
    },
    data: () => ({
		paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption(),
		img_default: require(`@/assets/default_image.webp`)
	}),
    created(){},
    mounted(){
		this.$nextTick(async () => {
			await this.getPaginated();
		})
	},
    computed:{

		...mapGetters("line_item", ["result_pag_assoc_creative"]),
		...mapGetters("profile", ["getAbility"]),

		getReadOnly(){
			return this.readonly;
		},

		getEntities(): LineItemAsso[] {
			const result: ResultPaginateAsso = this.result_pag_assoc_creative;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

        getSize() {
			return this.$t("show.creatives", {
				total: this.getEntities.length,
			});
		},

		getItems() {
			const items: Array<any> = [];
			this.getEntities.map((a: any) => {
				const item: any = {
					id: a.id,
					creative_thumbnail:a?.creative_thumbnail || this.img_default,
					creative_id: a?.creative_id,
					creative_external_id: a?.creative_external_id,
					creative_name: a?.creative_name,
					creative_size: a?.creative_size,
					start_date: isNull(a?.start_date) ? undefined : new Date(a?.start_date),
					end_date: isNull(a?.end_date) ? undefined : new Date(a?.end_date),
					line_item_id: a?.line_item_id,
					active: !!a?.active,
					edited: false,
					valid: true,
				};
				items.push(item);
			});
			return items;
		},

		canListAssoc(){
			return this.getAbility.can(this.getPermission.complement.actions.index_assoc, this.getPermission.complement.subject);
		},

		getPermission(){
			return getPermisionLineItem();
		},

		getPermissionFromAssoc(){
			return this.getPermission.complement;
		}
    },
    methods:{
		...mapActions("line_item", ["paginatedAssocCreative"]),
		...mapActions("loading", ["setLoadingData"]),

		async getPaginated() {
			await this.setLoadingData(TypeLoading.loading);
			this.filters["line_item_id"] = this.line_item.id;
			await this.paginatedAssocCreative({
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
			});
			await this.setLoadingData();
		},

		updatePaginate(data: any) {
			this.paginated.page = data;
		},

		async updateTable(){
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async updateParams(params: { filters: any; options: SortingOption }) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] = typeof params.value !== "undefined" ? params.value : "";
		},

		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			params.options.sort =  dictionary[params.options.sort];
			await this.updateParams({filters: this.filters, options: params.options});
		},
		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			this.updatePaginate(1);
			await this.getPaginated();
		},

	},
	watch:{
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
	}
});
