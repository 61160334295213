
import Vue from "vue";

export default Vue.extend({
  name: "OverviewLineItem",

  props: {
    retailer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  data: () => ({}),

  created() {},

  mounted() {
    this.$nextTick(async function () {});
  },

  computed: {},
  methods: {
    async selectRetailer(retailer: any) {
      this.$emit("change", { key: "retailer", value: retailer });
    },
  },
  watch: {},
});
