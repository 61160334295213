
import Vue from "vue";
import { isUndefined, isNull, isEmpty, debounce } from "lodash";
// @ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
// @ts-ignore
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import RadioButton from "@/components/Content/RadioButton.vue";
// @ts-ignore
import { getError } from "@/utils/resolveObjectArray";
// @ts-ignore
import CampaignSummary from "@/components/Content/CampaignSummary.vue";
// @ts-ignore
import {
  isRequired,
  isAfterToday,
  isAfterCompare,
  isMin,
  isMax,
  isSameOrAfterToday,
  adjustDate,
  isMaxCustomLength,
  // @ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import { getToday } from "@/services/date-service";
// Configs to Date
// @ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import { mapActions } from "vuex";
// @ts-ignore
import CombineBulletGraph from "@/views/Admin/LineItem/Dependencies/GraphicBidWin/Cards/CombineBullet/index.vue";
// @ts-ignore
// import { GraphicBidWin } from "@/interfaces/line_item";
import SelectorRetailer from "@/views/Admin/LineItem/Dependencies/Forms/SelectorRetailer.vue";

const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const OPTIMIZATION_BY_CAMPAIGN = "By Campaign";

export default Vue.extend({
  name: "OverviewLineItem",

  props: {
    line_item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    resources: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: function () {
        return {};
      },
    },
    field_label: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dataGraphic: {
      type: Object,
      default: function () {
        return {};
      },
    },
    canActiveLineItem: {
      type: Boolean,
      required: true,
    },
    retailer_isvalid: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    CardTextField,
    CardAutocomplete,
    CardSwitch,
    DatePicker,
    DataPickerStarTime,
    RadioButton,
    Separator,
    CampaignSummary,
    CardActions,
    ButtonTooltip,
    CombineBulletGraph,
    SelectorRetailer,
  },

  data: () => ({
    title: "OverviewLineItem",
    valid: true,
    open_campaign_summary: false,
    campaign_summary: [],

    // ParÃ¡metros usados para Start y End Date
    start_date_rules: [],
    end_date_rules: [],
    is_changed_date: false,

    min_date: "",
    max_date: "",

    cols: {
      col_lg_1: 12,
      col_lg_2: 4,
      col_lg_3: 4,
      col_md: 6,
    },

    orderCol: {
      order_Camp: 1,
      order_camp_name: 2,
      order_btn_camp: 3,
      order_adv_id: 4,
      order_adv_name: 5,
      order_allows_events: 6,
      order_radio: 7,
    },

    config: {
      timeout: 5000,
      open: false,
      text: "",
      color: "",
    },
  }),

  created() {},

  mounted() {
    this.$nextTick(async function () {
      await this.configLayout();
      this.resetValidation();
      this.min_date = await this.getDate("start_date");
    });
  },

  computed: {
    ...mapGetters("profile", ["isRolReport", "isAccountRM"]),

    getRetailers() {
      return this.$store.state.profile.account.retailers;
    },

    isReadOnly() {
      return this.isRolReport;
    },

    getRules() {
      return {
        isRequired,
        isAfterToday,
        isAfterCompare,
        isSameOrAfterToday,
        isMin,
        isMax,
        budget: [isRequired, isMax(this.getLineItem.budget, this.resources.selected_campaign?.budget)],
        isMaxCustomLength,
      };
    },

    getLineItem: {
      set(val: any) {},
      get() {
        return this.line_item;
      },
    },

    getToday() {
      return getToday(DEFAULT_DATE_TIME_FORMAT);
    },

    getMinDate() {
      return this.min_date;
    },

    getMaxDate() {
      return this.max_date;
    },
    isOptimizeCPM() {
      return this.line_item?.strategy_id == 9;
    },
    isOptimizeCPC() {
      return this.line_item?.strategy_id == 10;
    },
    isOptimizeVCR() {
      return this.line_item?.strategy_id == 11;
    },
    isFixBidS() {
      return this.line_item?.bid_strategy_id == 24;
    },
    isAutomatedBigS() {
      return this.line_item?.bid_strategy_id == 25;
    },
    isEdit() {
      return this.hasData(this.getLineItem.id);
    },

    /**
     * STRING RULES
     */

    calcSuggested() {
      if (!this.getLineItem.line_duration) return -1;
      const calc = Math.ceil(
        Number(this.resources.selected_campaign?.budget_remaining) / Number(this.getLineItem.line_duration),
      );
      return isFinite(calc) && !isNaN(calc) ? calc : 0;
    },

    getSuggested() {
      if (this.calcSuggested === -1) return "";
      return isNull(this.calcSuggested) ? null : `Suggested ${this.calcSuggested}`;
    },

    getDailyBudgetHint() {
      let isSuggested = this.calcSuggested <= this.getLineItem.daily_budget;
      return this.getError("daily_budget") || (!isSuggested && this.$t("daily_budget_not_suggested")) || "";
    },

    getErrors() {
      return this.$store.state.proccess.errors;
    },

    routeToCampaign() {
      return `/admin/campaigns/edit/${this.getLineItem.campaign_id}`;
    },

    getAppendOuterData() {
      if (!this.getLineItem.campaign_id) return null;
      return {
        icon: "mdi-link",
        text: "Go to Campaign",
        to: this.routeToCampaign,
      };
    },

    getLinePacingItems() {
      if (this.isEditPacing) {
        const pacingData = this.resources.line_pacings.filter(
          e => String(e.value).toUpperCase() == String("Lifetime").toUpperCase(),
        );
        return pacingData;
      } else {
        return this.resources.line_pacings;
      }
    },

    getStrategyItems() {
      if ((this.isOptimizationByLine || this.isOptimizationByCampaign) && this.isCampaignPMP) {
        const strategyObj = this.resources.strategies_filtered.filter(
          e => String(e.value).toUpperCase() == String("Optimized CPM").toUpperCase(),
        );
        return strategyObj;
      } else {
        return this.resources.strategies_filtered;
      }
    },

    getBidStrategyItems() {
      if ((this.isOptimizationByLine || this.isOptimizationByCampaign) && this.isCampaignPMP) {
        return this.resources.bid_strategies.filter(e => String(e.value).toUpperCase() == String("Fix").toUpperCase());
      } else {
        if (this.isOptimizeCPC || this.isOptimizeVCR) {
          return this.resources.bid_strategies.filter(
            e => String(e.value).toUpperCase() == String("Automated").toUpperCase(),
          );
        } else {
          return this.resources.bid_strategies;
        }
      }
    },

    getDataGraphicBidWin() {
      return this.dataGraphic;
    },

    isCampaignPMP() {
      return this.resources.selected_campaign?.campaign_pmp;
    },

    isEditPacing() {
      return this.isOptimizationByLine && this.isCampaignPMP;
    },

    isEditStrategyAndBidStrategy() {
      return (this.isOptimizationByCampaign || this.isOptimizationByLine) && this.isCampaignPMP;
    },

    isOptimizationByLine() {
      return this.resources.selected_campaign?.optimization_strategy?.description !== OPTIMIZATION_BY_CAMPAIGN;
    },

    isOptimizationByCampaign() {
      return this.resources.selected_campaign?.optimization_strategy?.description == OPTIMIZATION_BY_CAMPAIGN;
    },
  },
  methods: {
    async selectRetailer(retailer: any) {
      this.getLineItem.retailer = retailer;
      this.$emit("change", { key: "retailer", value: retailer });
    },

    async getDate(type: any) {
      return adjustDate(this.resources.selected_campaign[type]);
    },

    async configLayout() {
      if (this.isEdit) {
        this.cols.col_lg_1 = 6;
        this.cols.col_lg_2 = 6;
        this.cols.col_lg_3 = 3;
        this.cols.col_md = 4;
        this.orderCol.order_Camp = 3;
        this.orderCol.order_camp_name = 4;
        this.orderCol.order_btn_camp = 2;
        this.orderCol.order_adv_id = 5;
        this.orderCol.order_adv_name = 6;
        this.orderCol.order_radio = 1;
      } else {
        this.cols.col_lg_1 = 12;
        this.cols.col_lg_2 = 4;
        this.cols.col_lg_3 = 4;
        this.cols.col_md = 6;
        this.orderCol.order_Camp = 1;
        this.orderCol.order_camp_name = 2;
        this.orderCol.order_btn_camp = 3;
        this.orderCol.order_adv_id = 4;
        this.orderCol.order_adv_name = 2;
        this.orderCol.order_radio = 6;
      }
    },

    async updateGraphic(value: any) {
      this.$emit("update-graphic", value);
    },

    hasData(attr: any) {
      return !isUndefined(attr) && !isNull(attr) && isEmpty(attr);
    },

    getError(index: any) {
      return getError(this.getErrors, index);
    },

    getColor(active: Boolean) {
      return active ? "text-bold green--text" : "text-bold red--text";
    },

    hasError(index: string | number) {
      return this.errors.hasOwnProperty(index);
    },

    resetValidation() {
      let form = this.$refs.form;
      form.resetValidation();
    },

    async validate() {
      let form = this.$refs.form;
      const valid = await form.validate();
      return await valid;
    },

    /**
     * Returns Start Date Validations.
     * @param isSubmit On Submit.
     * @param verify Enable date verification if  is same or later than today.
     */
    async addStartDateValidations(isSubmit: boolean = false) {
      this.start_date_rules = [];

      /*
									this.end_date_rules = [
										this.getRules.isRequired,
										this.getRules.isAfterCompare(
											this.getLineItem.end_date,
											this.getLineItem.start_date
										)
									];*/

      if (!isSubmit) return this.start_date_rules;

      this.start_date_rules.push(this.getRules.isRequired);

      if (this.isEdit && this.is_changed_date) {
        this.start_date_rules.push(this.getRules.isSameOrAfterToday(this.getLineItem?.start_date));
      }

      if (!this.isEdit) {
        this.start_date_rules.push(this.getRules.isSameOrAfterToday(this.getLineItem?.start_date));
      }

      return this.start_date_rules;
    },
    /**
     * Tootip data
     * @param params
     * @returns
     */
    tooltipData(params: any) {
      return {
        icon: "mdi-information-outline",
        text: params,
        to: "",
        right: params?.right ?? true,
      };
    },
    async changeDate(date: any) {
      this.is_changed_date = true;
      await this.addStartDateValidations(true);
    },

    handleChange(event: any, key: any) {
      this.$emit("change", { key: key, value: event });
    },

    handleSelectorChange(payload: { key: string; value: any }) {
      this.$emit("change", payload);
    },

    handleCancel() {
      try {
        this.$emit("handle-cancel");
      } catch (error) {
        console.error("handleCancel", { error: error });
      }
    },

    setSnackBarConfig(open: boolean, text: string, color: string) {
      this.config = { ...this.config, open, text, color };
    },

    async handleSubmit(params: { continue: Boolean } = { continue: true }) {
      try {
        await this.addStartDateValidations(true);

        if (!(await this.validate())) return;

        if (this.isAccountRM && !this.getLineItem?.retailer?.id) {
          this.setSnackBarConfig(true, "The retailer is required", "error");
          return;
        }

        this.$emit("save", {
          method: this.isEdit ? "update" : "create",
          continue: params.continue,
          data: await this.prepareDataLineItem(),
        });
      } catch (error) {
        console.error("handleSubmit", { error: error });
      }
    },

    /**
     * From CardActions - line item
     * @param action
     */
    async handleAction(action: { type: any }) {
      switch (action.type) {
        case "save":
          await this.handleSubmit({ continue: false });
          break;

        case "save-continue":
          await this.handleSubmit({ continue: true });
          break;

        case "cancel":
          this.handleCancel();
          break;
      }
    },

    gotToCampaign() {
      this.$router.push({ name: "LineItemList" });
    },

    async prepareDataLineItem() {
      return {
        id: this.isEdit ? this.getLineItem.id : undefined,
        allows_events: this.getLineItem.allows_events ?? false,
        advertiser_id: this.getLineItem?.advertiser_id,
        retailer_id: this.isAccountRM ? this.getLineItem?.retailer.id : undefined,
        campaign_id: this.getLineItem?.campaign_id,
        name: this.getLineItem.name,
        budget: this.getLineItem?.budget,
        daily_budget: this.getLineItem?.daily_budget,
        start_date: this.moment(this.getLineItem.start_date).format(DEFAULT_DATE_TIME_FORMAT),
        end_date: this.moment(this.getLineItem.end_date).format(DEFAULT_DATE_TIME_FORMAT),
        active: this.getLineItem?.active,
        alternative_id: this.getLineItem.alternative_id,
        bid_strategy_id: this.getLineItem?.bid_strategy_id,
        strategy_id: this.getLineItem?.strategy_id,
        line_pacing_id: this.getLineItem.line_pacing_id,
        line_item_type_id: this.getLineItem?.line_item_type_id,
        // bid_shading_id: this.getLineItem?.bid_shading_id,
        creative_method_id: this.getLineItem.creative_method_id,
        fix_cpm: this.getLineItem?.fix_cpm,
        cpm_bid: this.getLineItem?.cpm_bid,
        target_ecpc: this.getLineItem?.target_ecpc,
        expected_ctr: this.getLineItem?.expected_ctr / 100,
        target_vcr: this.getLineItem?.target_vcr / 100,
        max_cpm_bid: this.getLineItem?.max_cpm_bid,
        frequency_caps: this.getLineItem?.frequency_caps,
      };
    },

    getCalculateDuration() {
      if (!this.isValidDates()) return undefined;
      const startDate = this.moment(this.getLineItem.start_date);
      const endDate = this.moment(this.getLineItem.end_date);
      let days = this.calculateDuration(startDate, endDate);

      if (days < 0) {
        // this.getLineItem.end_date = "";
        this.getLineItem.line_duration = undefined;
        return undefined;
      }

      return days;
    },

    calculateDuration(start: any, end: any) {
      if (!(start.isValid() && end.isValid())) {
        return -1;
      }
      const duration = this.moment.duration(end.diff(start));
      return Math.ceil(duration.asDays());
    },

    isValidDates() {
      const startDate = this.moment(this.getLineItem.start_date);
      const endDate = this.moment(this.getLineItem.end_date);
      return startDate.isValid() && endDate.isValid();
    },

    addRowFrecuency() {
      this.$emit("init-frequency-caps");
    },

    deleteRowFrecuency(index: number) {
      if (this.getLineItem.frequency_caps.length === 0) return;
      this.getLineItem.frequency_caps.splice(index, 1);
    },

    //Clear Fields
    clearFieldCampaing() {
      this.$emit("clear", "clear-relations");
    },

    // fetching
    async fetchResource(key: any, value?: any) {
      this.$emit(`fetch-resource`, { resource: key, value: value });
    },

    openCampaignSummary(open: Boolean) {
      this.open_campaign_summary = open;
    },

    changeCPMBid(value: any) {
      this.updateCPMBid(value);
    },

    updateCPMBid: debounce(async function (value: any) {
      this.$emit("change", { key: "max_cpm_bid", value: value });
    }, 500),
  },
  watch: {
    async isEdit(val, old) {
      if (val) {
        await this.configLayout();
      }
    },

    async "getLineItem.campaign_id"(val, old) {
      if (isNull(val)) {
        this.$emit("clear", "clear-relations");
      } else {
        this.$emit("change", { key: "campaign_id", value: val });
        await this.fetchResource("campaign_id", val);
      }
    },

    "getLineItem.line_item_type_id"(val, old) {
      this.$emit("change", { key: "line_item_type_id", value: val });
    },

    async "resources.selected_campaign.start_date"(val, old) {
      this.min_date = await this.getDate("start_date");
    },

    async "resources.selected_campaign.end_date"(val, old) {
      this.max_date = await this.getDate("end_date");
    },

    "getLineItem.strategy_id"(val, old) {
      this.$emit("change", { key: "strategy_id", value: val });
    },

    "getLineItem.bid_strategy_id"(val, old) {
      this.$emit("change", { key: "bid_strategy_id", value: val });
    },

    "getLineItem.budget_type_id"(val, old) {
      this.$emit("change", { key: "budget_type_id", value: val });
    },

    "getLineItem.line_pacing_id"(val, old) {
      this.$emit("change", { key: "line_pacing_id", value: val });
    },

    "getLineItem.target_ecpc"(val, old) {
      this.$emit("change", { key: "target_ecpc", value: val });
    },

    "getLineItem.expected_ctr"(val, old) {
      //.$emit("change", { key: "expected_ctr", value: val });
    },

    "getLineItem.max_cpm_bid"(val, old) {
      this.$emit("change", { key: "max_cpm_bid", value: val });
    },

    async "getLineItem.start_date"(val, old) {
      this.$emit("change", { key: "line_duration", value: undefined });
    },

    async "getLineItem.end_date"(val, old) {
      this.$emit("change", { key: "line_duration", value: undefined });
    },
  },
});
